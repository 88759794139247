/*
 * Add color theme colors to the window object
 * so this can be used by the charts and can be used by all others
 */

const theme = {
  "primary": "#ffc13e",
  "secondary": "#222e3c",
  "success": "#24b253",
  "info": "#5ca1e5",
  "warning": "#ffd400",
  "danger": "#e54c2e",
  "white": "#fff",
  "gray-100": "#dedfe7",
  "gray-200": "#bdbdcf",
  "gray-300": "#9ca3b5",
  "gray-400": "#7b84a1",
  "gray-500": "#5b6684",
  "gray-600": "#424d60",
  "gray-700": "#2c3340",
  "gray-800": "#191c26",
  "gray-900": "#0e1013",
  "black": "#000"
};

// Add theme to the window object
window.theme = theme;