import "../scss/app.scss";

// htmlkit (required)
import "./modules/jquery";

import "./modules/bootstrap";
import "./modules/sidebar";
import "./modules/theme";

// Charts
import "./modules/chartjs";

// Forms
// Date picker
import "./modules/flatpickr";

// Toast
import "./modules/notyf";


// init tooltips
$(function () {
  $('[data-toggle="tooltip"]').tooltip();
})
