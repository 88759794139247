
import { Notyf } from "notyf";

// default configuration
const notyf = new Notyf({
    duration: 4000,
    position: {
        x: 'right',
        y: 'top',
    },
    types: [
        {
            type: 'warning',
            background: theme.warning,
            className: "text-secondary",
            icon: {
                className: 'fa fa-warning fa-1 text-secondary',
                tagName: 'i'
            }
        },
        {
            type: 'error',
            background: 'indianred',
            duration: 8000,
            dismissible: true
        }
    ]
});

notyf.warning = function(message) {
    return notyf.open({
        type: 'warning',
        message: message
    });
};

window.notyf = notyf;